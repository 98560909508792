import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2db1adc2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-div" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_block = _resolveComponent("loading-block")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_home_button = _resolveComponent("home-button")!
  const _component_Alert = _resolveComponent("Alert")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$store.state.device === 'mobile')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass({ 'app-main': true, [_ctx.$route.name]: true })
        }, [
          _createVNode(_component_loading_block),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_router_view, {
              class: _normalizeClass({ blur: _ctx.$store.state.loading })
            }, null, 8, ["class"])
          ]),
          _createVNode(_Transition, { name: "fade" }, {
            default: _withCtx(() => [
              _createVNode(_component_home_button, {
                class: _normalizeClass({ blur: _ctx.$store.state.loading })
              }, null, 8, ["class"])
            ]),
            _: 1
          })
        ], 2))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass({ 'app-main': true, [_ctx.$route.name]: true })
        }, [
          _createVNode(_component_loading_block),
          _createElementVNode("div", null, [
            _createVNode(_component_router_view, {
              class: _normalizeClass({ blur: _ctx.$store.state.loading })
            }, null, 8, ["class"])
          ])
        ], 2)),
    _withDirectives(_createVNode(_component_Alert, {
      buttonText: _ctx.$store.state.alert.confirm_button,
      buttonCancel: _ctx.$store.state.alert.cancel_button.length > 0,
      onClick: _cache[0] || (_cache[0] = () => _ctx.$store.state.alert.callback()),
      onClose: _cache[1] || (_cache[1] = () => _ctx.$store.dispatch('alert/setVisiable', false)),
      msg: _ctx.$store.state.alert.msg,
      title: _ctx.$store.state.alert.title,
      showArrow: _ctx.$store.state.alert.showArrow
    }, null, 8, ["buttonText", "buttonCancel", "msg", "title", "showArrow"]), [
      [_vShow, _ctx.$store.state.alert.visible]
    ])
  ]))
}